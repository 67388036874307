import {NgModule} from '@angular/core';
import {NoPreloading, PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {Auth0BusinessGuard} from './guards/auth0business.guard';
import {Auth0CallbackComponent} from './public/auth0-callback/auth0-callback.component';
import {Auth0adminGuard} from './guards/auth0admin.guard';

const routes: Routes = [
    {
        path: 'test',
        canActivate: [Auth0adminGuard],
        loadChildren: './test/test.module#TestPageModule',
        pathMatch: 'full'
    },
    {
        path: '',
        loadChildren: './public/start/start.module#StartPageModule',
        pathMatch: 'full'
    },


    {path: 'authorization-callback', loadChildren: './public/auth/auth.module#AuthPageModule'},

    {path: 'public/bitly', loadChildren: './public/bitly/bitly.module#BitlyPageModule'},
    {
        path: 'public/success',
        loadChildren: () => import('./public/success/success.module').then( m => m.SuccessPageModule)
    },


    {path: 'public/qr-creator',
        canActivate: [Auth0BusinessGuard], loadChildren: './public/qr-creator/qr-creator.module#QrCreatorPageModule'},

    {path: 'auth0', loadChildren: './public/auth0/auth0.module#Auth0PageModule'},
    {path: 'auth0-callback', component: Auth0CallbackComponent},
    {path: 'auth0-two-factor', loadChildren: './public/auth0-two-factor/auth0-two-factor.module#Auth0TwoFactorPageModule'},

    {path: 'l/:hash', loadChildren: './public/l/l.module#LPageModule'},
    {path: 'qr/:uuid', loadChildren: './public/qr/qr.module#QrPageModule'},
    {
        path: 'logout',
        loadChildren: './logout/logout.module#LogoutPageModule'
    },
    {
        path: 'logout-success', loadChildren: './logout-success/logout-success.module#LogoutSuccessPageModule'
    },
  {
    path: 'public/assign',
      canActivate: [Auth0BusinessGuard],
    loadChildren: () => import('./public/assign/assign.module').then( m => m.AssignPageModule)
  },
  {
    path: 'public/demomode',
      canActivate: [Auth0BusinessGuard],
    loadChildren: () => import('./public/salesforce/demomode/demomode.module').then( m => m.DemomodePageModule)
  },
    {
        path: 'deploy',
        canActivate: [Auth0BusinessGuard],
        loadChildren: () => import('./public/salesforce/deploy/deploy.module').then( m => m.DeployPageModule)
    },
  {
    path: 'private/payments/monitor',
      canActivate: [Auth0BusinessGuard],
    loadChildren: () => import('./private/payments/monitor/monitor.module').then( m => m.MonitorPageModule)
  },
  {
    path: 'dwolla',
    loadChildren: () => import('./dwolla/dwolla.module').then( m => m.DwollaPageModule)
  },
  {
    path: 'signups',
    loadChildren: () => import('./signups/signups.module').then( m => m.SignupsPageModule)
  },



];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: NoPreloading, enableTracing: false, onSameUrlNavigation: 'reload'})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
